// @ts-ignore
import CultureTeam from './CultureTeam/CultureTeam.tsx';

import './Culture.css';

function Culture() {

    return (
        <div className="culture section--dark">
            <div className="culture__title">CULTURE</div>
            <div className="culture__headline">
                We believe in investing in people and forming meaningful bonds
            </div>
            <CultureTeam></CultureTeam>
            <div className="culture__copy">
                Founded in 2023, Fortuna is at the forefront of shaping the digital future. Our ethos is simple:
                create excellent products that simplify and enhance lives, not complicate them. With each project, we
                aim to help clients navigate the digital world - crafting seamless interfaces, intuitive designs, and
                cutting-edge technology solutions that resonate deeply with their users. Our team is driven by a passion
                for innovation, an eye for beauty, and a commitment to pushing the boundaries of what's possible. Join
                us in redefining the digital experience.
            </div>
        </div>
    );
}

export default Culture;