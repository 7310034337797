// @ts-ignore
import ExpertiseStack from "./ExpertiseStack/ExpertiseStack.tsx";

import './Expertise.css'

function Expertise() {
    return <section className="expertise section--dark">
        <div className="expertise__title">EXPERTISE</div>
        <ExpertiseStack></ExpertiseStack>
    </section>
}

export default Expertise