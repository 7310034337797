import './ProjectResults.css';
import Markdown from 'react-markdown'


function ProjectResults({results, resultsImage, outcome}) {
    /* <div className="project-results-nav">
        <div className="project-results-nav-card">
            {results.map((result: object) => (
                <div className="project-results-item">
                    <div className="project-results-item__title">{result.title}</div>
                    <div className="project-results-item__description">{result.description}</div>
                </div>
            ))}
            <br/>&nbsp;
        </div>
        <div className="project-results-nav-card">
            <div className="project-results-image">
                <img className="project-results-image_tag" loading="lazy" src={resultsImage} alt="Project results"
                     sizes="100vw"/>
            </div>
        </div>
    </div> */
    return (
        <section className="project-results section--light">
            <div className="project-results__title">RESULTS</div>
            <div className="project-results__body">
                <Markdown>{outcome}</Markdown>
            </div>
        </section>
    );
}

export default ProjectResults;