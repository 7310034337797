//@ts-ignore
import ProcessPhase from "../ProcessPhase/ProcessPhase.tsx";
//@ts-ignore
import process_discover from '../../../assets/images/process/discover.svg';
//@ts-ignore
import process_define from '../../../assets/images/process/define.svg';
//@ts-ignore
import process_develop from '../../../assets/images/process/develop.svg';
//@ts-ignore
import process_deliver from '../../../assets/images/process/deliver.svg';

import './ProcessStack.css';

function ProcessStack() {
    return <section className="process-stack">
        <ProcessPhase
            key={1}
            name="DISCOVER"
            icon_path={process_discover}
            description="We explore your business and challenges to grasp your product vision."
        ></ProcessPhase>
        <ProcessPhase
            key={2}
            name="DEFINE"
            icon_path={process_define}
            description={
                "We establish your product vision with clear milestones, timelines, and a focused scope of " +
                "work."
            }
        ></ProcessPhase>
        <ProcessPhase
            key={3}
            name="DEVELOP"
            icon_path={process_develop}
            description="We create design assets and build scalable, robust software based on the defined solution."
        ></ProcessPhase>
        <ProcessPhase
            key={4}
            name="DELIVER"
            icon_path={process_deliver}
            description={
                "By iterating quickly and regularly checking in with clients, we ensure the final product meets user " +
                "and market needs."
            }
        ></ProcessPhase>
    </section>
}

export default ProcessStack;