import './ProjectApproach.css';
import Markdown from "react-markdown";

function ProjectApproach({approach}) {

    return (
        <>
            <section className="project-implementation section--dark">
                <div className="project-implementation__title">APPROACH</div>
            </section>
            <div className="project-problem__description">
                <Markdown>{approach}</Markdown>
            </div>
        </>
    );
}

export default ProjectApproach;