// @ts-ignore
import ExpertiseCard from "../ExpertiseCard/ExpertiseCard.tsx";
import './ExpertiseStack.css';

function ExpertiseStack() {
    let capabilities = [
        {
            title: "DIGITAL STRATEGY AUDIT",
            description: "At Fortuna, our Digital Strategy Audit enhances your online presence by examining goals, " +
                "audience engagement, content, and technology. Let us help you refine your strategy and stay ahead in " +
                "the digital landscape.",
        },
        {
            title: "UI/UX AUDIT",
            description: "Our UI/UX Audit enhances your product's user experience by scrutinizing and improving every " +
                "touchpoint. We assess usability, accessibility, and emotional response, providing actionable insights " +
                "to meet your users",
        },
        {
            title: "INTEGRATED PRODUCT DEVELOPMENT",
            description: "Our Integrated Product Development process seamlessly combines design and technology, taking " +
                "ideas from inception to market-ready masterpieces. With strategic planning, innovative design, and " +
                "robust engineering practices, we set the standards in digital excellence, focusing on quality, " +
                "scalability, and user-centric design.",
        },
    ]

    return (
        <section className="expertise-stack">
            {capabilities.map((capability, idx) => (
                <ExpertiseCard key={idx} index={idx} title={capability.title} description={capability.description} />
            ))}
        </section>
    );

}

export default ExpertiseStack