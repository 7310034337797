// @ts-ignore
import hero from '../../assets/videos/hero-background.mp4';
import './Hero.css';

function Hero() {
    return (
        <section className="hero section--pink">
            <video autoPlay loop muted className="hero__background-video">
                <source src={hero} type="video/mp4"/>
            </video>

            <div className="hero__copy">
                FORTUNA HELPS FUNDED STARTUPS DESIGN AND BUILD WORLD CLASS DIGITAL PRODUCTS
            </div>
        </section>
    )
}

export default Hero