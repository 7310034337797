import './ExpertiseCard.css'

// @ts-ignore
import star_pink from '../../../assets/images/star-pink.svg';

function ExpertiseCard({ index, title, description }){
    let indexToMarkers = {
        0: ["A.", "01"],
        1: ["B.", "02"],
        2: ["C.", "03"],
    }

    let markers = indexToMarkers[index]
    return <section className="expertise-card">
        <p className="expertise-card__marker">{markers[0]}</p>
        <h1 className="expertise-card__title">{title}</h1>
        <p className="expertise-card__marker">{markers[1]}</p>
        <img src={star_pink} className="expertise-card__star"/>
        <p  className="expertise-card__description">{description}</p>
    </section>
}

export default ExpertiseCard